exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-auth-tsx": () => import("./../../../src/pages/auth.tsx" /* webpackChunkName: "component---src-pages-auth-tsx" */),
  "component---src-pages-company-about-tsx": () => import("./../../../src/pages/company/about.tsx" /* webpackChunkName: "component---src-pages-company-about-tsx" */),
  "component---src-pages-cookies-policy-mdx": () => import("./../../../src/pages/cookies-policy.mdx" /* webpackChunkName: "component---src-pages-cookies-policy-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-product-device-software-tsx": () => import("./../../../src/pages/product/device-software.tsx" /* webpackChunkName: "component---src-pages-product-device-software-tsx" */),
  "component---src-pages-product-fleet-management-tsx": () => import("./../../../src/pages/product/fleet-management.tsx" /* webpackChunkName: "component---src-pages-product-fleet-management-tsx" */),
  "component---src-pages-terms-of-service-mdx": () => import("./../../../src/pages/terms-of-service.mdx" /* webpackChunkName: "component---src-pages-terms-of-service-mdx" */)
}

